import React from 'react'

const Authorization = ({children}) => {
    if (!window.sessionStorage.getItem("isLoggedIn")) {
        return window.location.href === '/login'
    }
    return (
        <div>{children}</div>
    )
}

export default Authorization